import React from 'react';
import './App.css';

import PulseLogo from './assets/images/pulse-logo-light.png';

const JoinBetaButton = () => {
  return (
    <a href='https://airtable.com/login?continue=%2Fapp8BOq6matPj8drP%2FshrH3fxozuv7gTAT2&redirectSource=sharedForm' className="bg-tertiary-900 hover:bg-tertiary-500 transition text-secondary-100 font-semibold px-4 rounded-full text-sm w-32 py-3 block text-center">
      Join Beta
    </a>
  );
}

function App() {
  return (
    <div className='App bg-primary-900 w-full flex justify-center'> 
      <div className='flex flex-col py-8 w-4/5'>
        <div className="flex flex-row w-full items-center justify-between align-middle">
          <a href="https://usepulse.co"><img src={PulseLogo} alt="Pulse Logo" className="w-14" /></a>
          <JoinBetaButton />
        </div>
        <div className="flex-1 flex justify-center items-center">
          <div className="text-left sm:w-3/5 w-full sm:mb-40">
            <h2 class="sm:text-4xl text-3xl mb-2 font-bold text-secondary-100 sm:w-2/3 w-full">A Modern OS for Private Market Funds</h2>
            <p className='mb-6 sm:text-lg font-normal text-secondary-200 sm:w-4/5 w-full'>We unlock data productivity for investors, increasing execution speed for investment analysis and internal workflows and surfacing insights for alpha.</p>
            <JoinBetaButton />
          </div>
        </div>
        <footer className="flex justify-center items-center text-secondary-200 text-sm">
          <p>Copyright &copy; 2024 Pulse, Inc.</p>
        </footer>
      </div>
     </div>
  );
}

export default App;
